<template>
  <div class="container estadisticas mt-4">
    <div class="row">
      <div class="col-12">
        
      </div>
      <div class="col-12">
        <card type="chart" cardCol>
          <template slot="header">
            <div class="row">
              <div class="col-12 col-lg-6">
                <h2 class="card-category">{{$t('estadistica.title1')}}</h2>
                <combo-box :options="options" name="rango de fechas" inputId="Seleccv" disabled="disabled" v-model="rango"  :label="$t('landing.label4')" rules="required"  :placeholder="$t('landing.placeholder4')" ></combo-box>
              </div>
              <div class="col-12 col-lg-6 text-right">
                <button class="btn-link btn btn-default">
                  {{$t('estadistica.button')}}
                  <span class="fas fa-download"></span>
                </button>
              </div>
            </div>
          </template>
          <div class="row no-gutters">
            <div class="col-12 col-lg-8">
              <p v-if="loaded">{{$t('estadistica.descripcion1')}} {{ stadistics[0].Fecha_acciones_semana }} {{$t('estadistica.descripcion2')}}</p>
            </div>
            <div class="col-12 mb-3 mt-5">
              <h3>{{$t('estadistica.subtitle')}}</h3>
            </div>
            <div class="data-stadistics">
              <div class="row no-gutters" v-for="item in stadistics" :key="item.Id_Registro">
                  <div class="col 12 col-lg-6">
                    <card type="stadistic default">
                      <div class="row">
                        <div class="col-6">
                          <div class="title">
                              {{$t('estadistica.titlecard1')}}
                          </div>
                        </div>
                        <div class="col-6 text-right">
                          <!-- <button class="btn btn-display blue">Mostrar</button> -->
                        </div>
                      </div>
                      <p class="mt-3" >{{item.Fecha_acciones_mes}}</p>
                      <p class="titlepor asc">{{item.Nro_clicks_mes}}</p>
                      <p>{{$t('estadistica.subtitlecard1')}} <span class="asc">200%</span> </p>
                      <!-- <line-chart
                        id="Prueba1"
                        class="chart-area" style="height: 300px"
                        :chartData="redesSociales"
                        :extraOptions="extraOptionsLine"
                      ></line-chart> -->
                    </card>
                  </div>
                  <div class="col 12 col-lg-6">
                    <card type="stadistic default">
                      <div class="row">
                        <div class="col-6">
                          <div class="title">
                              {{$t('estadistica.titlecard2')}}
                          </div>
                        </div>
                        <div class="col-6 text-right">
                          <!-- <button class="btn btn-display white">Mostrar</button> -->
                        </div>
                      </div>
                      <p class="mt-3" >{{item.Fecha_acciones_mes}}</p>
                      <p class="titlepor desc">{{item.Nro_acciones_mes}}</p>
                      <p>{{$t('estadistica.subtitlecard2')}} <span class="desc">200%</span> </p>
                      <!-- <line-chart
                        id="Prueba1"
                        class="chart-area" style="height: 300px"
                        :chartData="redesSociales"
                        :extraOptions="extraOptionsLine"
                      ></line-chart> -->
                    </card>
                  </div>
                  <div class="col 12 col-lg-6">
                    <card type="stadistic default">
                      <div class="row">
                        <div class="col-6">
                          <div class="title">
                              {{$t('estadistica.titlecard3')}}
                          </div>
                        </div>
                        <div class="col-6 text-right">
                          <!-- <button class="btn btn-display orange">Mostrar</button> -->
                        </div>
                      </div>
                      <p class="mt-3" >{{item.Fecha_impresiones_mes}}</p>
                      <p class="titlepor asc">{{item.Nro_impresiones_mes}}</p>
                      <p>{{$t('estadistica.subtitlecard3')}} <span class="asc">200%</span> </p>
                      <!-- <line-chart
                        id="Prueba1"
                        class="chart-area" style="height: 300px"
                        :chartData="redesSociales"
                        :extraOptions="extraOptionsLine"
                      ></line-chart> -->
                    </card>
                  </div>
                  <div class="col 12 col-lg-6">
                    <card type="stadistic default">
                      <div class="row">
                        <div class="col-6">
                          <div class="title">
                              {{$t('estadistica.titlecard4')}}
                          </div>
                        </div>
                        <div class="col-6 text-right">
                          <!-- <button class="btn btn-display white">Mostrar</button> -->
                        </div>
                      </div>
                      <p class="mt-3" >{{item.Fecha_vistas_mes}}</p>
                      <p class="titlepor asc">{{item.Nro_vistas_mes}}</p>
                      <p>{{$t('estadistica.subtitlecard4')}} <span class="asc">200%</span> </p>
                      <!-- <line-chart
                        id="Prueba1"
                        class="chart-area" style="height: 300px"
                        :chartData="redesSociales"
                        :extraOptions="extraOptionsLine"
                      ></line-chart> -->
                    </card>
                  </div>
                  <!-- <div class="col 12 col-lg-4">
                    <card type="stadistic default">
                      <div class="row">
                        <div class="col-6">
                          <div class="title">
                              Publicaciones
                          </div>
                        </div>
                        <div class="col-6 text-right">
                          <button class="btn btn-display purple">Mostrar</button>
                        </div>
                      </div>
                      <p class="mt-3" >12 de mayo - 18 de mayo</p>
                      <p class="titlepor asc">581</p>
                      <p>Seguidores de la página <span class="asc">200%</span> </p>
                    </card>
                  </div> -->
                  <!-- <div class="col 12 col-lg-4">
                    <card type="stadistic default">
                      <div class="row">
                        <div class="col-6">
                          <div class="title">
                              Interacción con la publicación
                          </div>
                        </div>
                        <div class="col-6 text-right">
                          <button class="btn btn-display white">Mostrar</button>
                        </div>
                      </div>
                      <p class="mt-3" >12 de mayo - 18 de mayo</p>
                      <p class="titlepor asc">581</p>
                      <p>Total impresiones a la página <span class="asc">200%</span> </p>
                    </card>
                  </div> -->
              </div>
            </div>
            
            <!-- <div class="col-12 mt-5 mb-3">
              <h3>Balance de seguidores</h3>
            </div>
            <div class="col 12 col-lg-4">
              <card type="stadistic default">
                <div class="row">
                  <div class="col-6">
                    <div class="title">
                        Adquiridos
                    </div>
                  </div>
                  <div class="col-6 text-right">
                    <button class="btn btn-display rose">Mostrar</button>
                  </div>
                </div>
                <p class="mt-3" >12 de mayo - 18 de mayo</p>
                <p class="titlepor asc">581</p>
                <p>Total me gusta a la página <span class="asc">200%</span> </p>
              </card>
            </div>
            <div class="col 12 col-lg-4">
              <card type="stadistic default">
                <div class="row">
                  <div class="col-6">
                    <div class="title">
                        Perdidos
                    </div>
                  </div>
                  <div class="col-6 text-right">
                    <button class="btn btn-display purple">Mostrar</button>
                  </div>
                </div>
                <p class="mt-3" >12 de mayo - 18 de mayo</p>
                <p class="titlepor asc">581</p>
                <p>Seguidores de la página <span class="asc">200%</span> </p>
              </card>
            </div>
            <div class="col 12 col-lg-4">
              <card type="stadistic default">
                <div class="row">
                  <div class="col-6">
                    <div class="title">
                        Nivel de respuesta
                    </div>
                  </div>
                  <div class="col-6 text-right">
                    <button class="btn btn-display white">Mostrar</button>
                  </div>
                </div>
                <p class="mt-3" >12 de mayo - 18 de mayo</p>
                <p class="titlepor asc">581</p>
                <p>Total impresiones a la página <span class="asc">200%</span> </p>
              </card>
            </div> -->
          </div>
        </card>
        <!-- <card type="chart default mt-5" >
          <template slot="header">
            <h2>Datos demográficos</h2>
          </template>
          <div class="row">
            <div class="col-12 col-lg-7">
              genero
            </div>
            <div class="col-12 col-lg-5">
              Edad
            </div>
          </div>
        </card> -->
      </div>

    </div>
    <modalpeq group="prueba3000" position="bottom left" />
  </div>
</template>

<style scoped>
.dashboard {
  padding-left: 50px;
  padding-right: 50px;
}
</style>

<script>
// @ is an alias to /src

import moment from "moment";
import Card from "@/components/card/Card.component.vue";
import ComboBox from "@/components/combobox/ComboBox.component";
// import LineChart from "@/components/charts/LineCharts";
import { mapMutations, mapState } from "vuex";
// import BarChart from "@/components/charts/BarChart";
// import Doughnut from "@/components/charts/Doughnut";
// import CustomLine from "@/components/charts/CustomExample";
import * as chartConfigs from "@/components/charts/config";
// import config from "@/components/charts/configlobal";
// import wordcloud from "vue-wordcloud";
// import VueApexCharts from "vue-apexcharts";

export default {
  name: "Estadisticas",
  components: {
    Card,
    ComboBox,
    // LineChart
    // LineChart,

  },
  data() {
    //TODO: hACER ALGO
    return {
      loaded: false,
      redesSociales: null,
      options: [
        this.$t("estadistica.select")
      ],
      rango: this.$t("estadistica.select"),
      extraOptionsLine: chartConfigs.stadisticsGreen,
      stadistics: null
    };
  },
  computed: {
    ...mapState(["social"]),
  },
  created() {
    
    // this.Carga();
    this.getStadistic()
  },
  methods: {
    ...mapMutations(["Carga"]),
    async getStadistic(){
      try {
        
        this.Carga();
        const id = this.social.filter(item => item.view == true);
        const { data } = await this.axios.get(`/bextsocial/statics/${id[0].Id_Empresa}/facebook`);        
        if(data == "No se encontro data relacionada"){
          this.loaded = false;
            this.Carga();
            this.$hijonew({
                group: "prueba3000",
                type: "danger",
                title: "Advertencia",
                text: "No se encuentra data relacionada con está cuenta",
                duration: 1000,
                speed: 1000,
            });
        } else{
          this.Carga();
          console.log(data);
          this.stadistics = data;
          this.loaded = true;
        }
      } catch (error) {
        console.log(error);
        this.Carga();
        this.$hijonew({
          group: "prueba3000",
          type: "danger",
          title: "Error",
          text: "Hay un problema con su red",
          duration: 1000,
          speed: 1000,
        });
      }
      

    }

  },
  mounted() {
    console.log("prueba mounted")
  //   this.loaded = false
  //   try {
  //     let prueba = "";
  //   let objetotags = JSON.parse(localStorage.getItem("rest"));
  //   objetotags.forEach(function(x, i) {
  //     //         //console.log(this.tags);
  //     if (i % 2 === 0) {
  //       if (i == 0) {
  //         if (i == objetotags.length - 1) {
  //           prueba = x.text;
  //         } else {
  //           prueba = x.text + ",";
  //         }
  //       } else if (i == objetotags.length - 1) {
  //         prueba = prueba + "," + x.text;
  //       } else {
  //         prueba = prueba + "," + x.text + ",";
  //       }
  //     } else {
  //       prueba = prueba + x.text;
  //     }
  //   });

  //   const { data } = await this.axios.get("/bextsocial/" + prueba + "");
  //     //this.chartdata = data
  //     this.loaded = true
  //     //this.fuentes()
  //     let objetoRedes = data;
  //     //console.log(data)
  //   var result = [];
  //   var map = new Map();
  //   objetoRedes.forEach((item) => {
  //     if (!map.has(item.Red_social)) {
  //       map.set(item.Red_social, true);    // set any value to Map
  //       result.push({
  //         RedSocial: item.Red_social,
  //         Descripcion: item.Descripcion,
  //         Sentimiento: item.Clasificacion_sentimiento,
  //         Fecha: item.Fecha_publicacion,
  //         Imagen: item.Imagen_Perfil,
  //         Seguidores: item.N_Seguidores_Amigos,
  //         Compartido: item.N_Veces_Compartida,
  //         User: item.Nombre_Usuario,
  //         Publicacion: item.Texto_Publicacion,
  //         children: new Array()
  //       });
  //     }
  //     else {
  //       var indextmp = null;
  //       var arr = result.filter(function (x, i) { if (x.Filter == item.Filtro) { indextmp = i } })
  //       result[indextmp].children.push({
  //         RedSocial: item.Red_social,
  //         Descripcion: item.Descripcion,
  //         Sentimiento: item.Clasificacion_sentimiento,
  //         Fecha: item.Fecha_publicacion,
  //         Imagen: item.Imagen_Perfil,
  //         Seguidores: item.N_Seguidores_Amigos,
  //         Compartido: item.N_Veces_Compartida,
  //         User: item.Nombre_Usuario,
  //         Publicacion: item.Texto_Publicacion,
  //       });
  //     }

  //   });
  //   var orq = [];
  //   var sentimiento = [];
  //   var oreq = [];
  //   var positivo, neutral, negativo;
  //   result.forEach((x) => {

  //     orq.push({
  //       "resocial": x.RedSocial,
  //       "post": x.children.length
  //     });
  //   })
  //   let mencionesq = this.menciones;
  //   data.forEach((x) => {
  //     var ad = sentimiento.filter(user => user.resocial == x.Clasificacion_sentimiento)
  //       if(ad.length == 0){
  //         positivo = data.filter(user => user.Clasificacion_sentimiento == "Positivo");
  //         neutral = data.filter(user => user.Clasificacion_sentimiento == "Neutral");
  //         negativo = data.filter(user => user.Clasificacion_sentimiento == "Negativo");
  //         if(x.Clasificacion_sentimiento == "Positivo"){

  //           // positivo.sort(function (a, b) {
  //           //   if (a.value > b.value) {
  //           //     return 1;
  //           //   }
  //           //   if (a.value < b.value) {
  //           //     return -1;
  //           //   }
  //           //   // a must be equal to b
  //           //   return 0;
  //           // });
  //           mencionesq.positivo = positivo
  //           sentimiento.push({
  //             "resocial": x.Clasificacion_sentimiento,
  //             "post": positivo.length
  //           })
  //         }
  //         else if(x.Clasificacion_sentimiento == "Neutral"){
  //           mencionesq.neutral = neutral
  //           sentimiento.push({
  //             "resocial": x.Clasificacion_sentimiento,
  //             "post": neutral.length
  //           })
  //         }
  //         else{
  //           mencionesq.negativo = negativo
  //           sentimiento.push({
  //             "resocial": x.Clasificacion_sentimiento,
  //             "post": negativo.length
  //           })
  //         }

  //       }
  //   })

  //   var positivo = [];
  //   var neutral =[];
  //   var negativo = [];
  //   var sieries = this.series
  //     sentimiento.forEach((we,ix) =>{
  //       orq.forEach((x,i) => {
  //         var ady = data.filter(otro => otro.Clasificacion_sentimiento == we.resocial && otro.Red_social == x.resocial)
  //         if(we.resocial == "Positivo"){positivo.push(ady.length)}
  //         if(we.resocial == "Neutral"){neutral.push(ady.length)}
  //         if(we.resocial == "Negativo"){negativo.push(ady.length)}
  //         var ser = sieries.filter(otro => otro.name == we.resocial)
  //         if(ser == 0){
  //           sieries.push({
  //             name: we.resocial,
  //             data: []
  //           })
  //         }
  //       })
  //     })


  //     sentimiento.forEach((item,index)=> {
  //       var resultado = sieries.filter(otro => otro.name == item.resocial)
  //       // var mencion = data.filter(x => x.Clasificacion_sentimiento == item.resocial )
  //       item.resocial == "Positivo" ? resultado[0].data = positivo : console.log("no es ese")
  //       item.resocial == "Neutral" ? resultado[0].data = neutral : console.log("no es ese")
  //       item.resocial == "Negativo" ? resultado[0].data = negativo : console.log("no es ese")
  //     })

  //   orq.forEach((item) => {
  //     this.chartOptionse.xaxis.categories.push(item.resocial)
  //   })

  //   var datetimes = [];
  //   var labelsfecha = [];
  //   data.forEach((x,i) => {
  //     const date = moment(x.Fecha_publicacion, "YYYYMMDD").format("MM/DD");
  //     let hoy = new Date("06-16-2020");
  //     if(i <= 8){
  //       hoy = hoy.setDate(hoy.getDate() - i)
  //       hoy = new Date(hoy)
  //       hoy = moment(hoy, "YYYYMMDD").format("MM/DD");
  //       let fecha = datetimes.filter(otro => otro == date )
  //       let labels = labelsfecha.filter(otro => otro == hoy )
  //       if(fecha.length == 0){
  //         if( date != "Invalid date"){
  //           datetimes.push(date);
  //         }
  //       }
  //       if(labels.length == 0){
  //         labelsfecha.push(hoy);
  //       }
  //     }
  //     else{
  //       let fecha = datetimes.filter(otro => otro == date )
  //       if(fecha.length == 0){
  //         if( date != "Invalid date"){
  //           datetimes.push(date);
  //         }
  //       }
  //     }
  //   })
  //   let datosline = []
  //   labelsfecha.forEach(x => {
  //     orq.forEach(item => {
  //       var getResul = data.filter(otro => moment(otro.Fecha_publicacion, "YYYYMMDD").format("MM/DD")  == x && otro.Red_social == item.resocial)
  //       if(getResul != 0){
  //         datosline.push({
  //           RedSocial: item.resocial,
  //           Fecha: x,
  //           dato: getResul.length
  //         });
  //       }
  //     })
  //   })
  //   var pushlabel = []
  //   labelsfecha.forEach(x => {
  //     pushlabel.push({
  //           label: x
  //       });
  //   })
  //   pushlabel[0].total = {label: "Twitter",
  //              fill: true,
  //              borderColor: config.colors.primary,
  //              borderWidth: 2,
  //              borderDash: [],
  //              //backgroundColor: config.colors.primaryGradient,
  //              backgroundColor:"transparent",
  //              borderDashOffset: 0.0,
  //              pointBackgroundColor: config.colors.primary,
  //             pointBorderColor: "rgba(255,255,255,0)",
  //             pointHoverBackgroundColor: config.colors.info,
  //              pointBorderWidth: 20,
  //              pointHoverRadius: 4,
  //              pointHoverBorderWidth: 15,
  //              pointRadius: 4,
  //              data: [80, 60, 70, 40, 50,60]}
  //   this.redesSociales = pushlabel
  //   console.log(this.redesSociales)


  //   this.chartdata = orq;
  //   this.chartdatasentimientos = sentimiento;

  //   } catch (e) {
  //     console.error(e)
  //   }

  //   //this.initBigChart();
  }
};
</script>