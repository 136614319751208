<template>
    <div class="notauthorized">
        <h1>No tiene acceso..</h1>
    </div>
</template>

<script>
export default {
    name: "Not Authorize",
    methods: {
        deleteLocale(){
            localStorage.removeItem('access_token');
            localStorage.removeItem('id_token');
            localStorage.removeItem('expires_at');
            localStorage.removeItem('user');
            localStorage.removeItem("rest");
            localStorage.removeItem("vuex");
        }
    },
    created() {
        this.deleteLocale();
        this.$auth.logout();
        // this.$router.push({ name: 'Landing' })
    },
}
</script>