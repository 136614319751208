<template>
    <div class="potenciar mt-5">
        <div class="container">
            <div class="row justify-content-center" v-if="potenciarstep == 1">
                <div class="col-12 col-lg-7" >
                    <card type="pontenciar default">
                        <div class="happy text-center">
                            <h1>{{$t('potenciar.wizard1.title')}}</h1>
                            <p>{{$t('potenciar.wizard1.subtitle')}}</p>
                            <img src="../assets/image/MicrosoftTeams-image.png" class="mt-3 mb-3" alt="Imagen"/>
                            <h2>{{$t('potenciar.wizard1.p1')}}</h2>
                            <p>{{$t('potenciar.wizard1.p2')}}</p>
                            <button type="button" class="btn-wizard" @click="Step()">{{$t('potenciar.wizard1.btn')}}</button>
                        </div>
                    </card>
                </div>
            </div>
            <div class="row justify-content-center" v-if="potenciarstep == 2" >
                <div class="col-12 text-center mb-4">
                    <input type="radio" name="field" v-model="typeplan" value="mensual"  checked="checked" id="Uno"/>
                    <label for="Uno">
                        <svg class="check" viewbox="0 0 20 20">
                        <defs>
                            <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="100%">
                            <stop offset="0%" stop-color="#8BC441"></stop>
                            <stop offset="100%" stop-color="#8BC441"></stop>
                            </linearGradient>
                        </defs>
                        <circle id="border" r="10px" cx="20px" cy="20px"></circle>
                        <circle id="dot" r="5px" cx="20px" cy="20px"></circle>
                        </svg>{{$t('potenciar.wizard2.pago1')}}
                    </label>
                    <input type="radio" name="field" v-model="typeplan" value="anual" id="Dos"/>
                    <label for="Dos">
                        <svg class="check" viewbox="0 0 20 20">
                        <defs>
                            <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="100%">
                            <stop offset="0%" stop-color="#8BC441"></stop>
                            <stop offset="100%" stop-color="#8BC441"></stop>
                            </linearGradient>
                        </defs>
                        <circle id="border" r="10px" cx="20px" cy="20px"></circle>
                        <circle id="dot" r="5px" cx="20px" cy="20px"></circle>
                        </svg>{{$t('potenciar.wizard2.pago2')}}
                    </label>
                    <span class="badge badge-green">{{$t('potenciar.wizard2.ahorro')}}</span>
                </div>
                <div class="col-12 col-lg-4">
                    <card type="pontenciar-two default">
                        <div class="pagos text-center">
                            <p>{{$t('potenciar.wizard2.card1.item1')}}</p>
                            <p>{{$t('potenciar.wizard2.card1.item2')}}</p>
                            <p>$ 50<span>{{$t('potenciar.wizard2.card1.mes')}}</span></p>
                            <p>{{$t('potenciar.wizard2.card1.p1')}}.</p>
                            <button type="button" class="btn-potenciar" @click="Step(191800)">{{$t('potenciar.wizard2.card1.btn')}}</button>
                            <p>{{$t('potenciar.wizard2.card1.mencion')}}</p>
                            <p>{{$t('potenciar.wizard2.card1.p-ultimate')}}</p>
                        </div>
                    </card>
                </div>
                <div class="col-12 col-lg-4">
                    <card type="pontenciar-two default active-pay">
                        <div class="pagos text-center">
                            <div class="popular">
                                <span>{{$t('potenciar.wizard2.card2.popu')}}</span>
                            </div>
                            <div class="content-card">
                                <p>{{$t('potenciar.wizard2.card1.item1')}}</p>
                                <p>{{$t('potenciar.wizard2.card1.item2')}}</p>
                                <p>$ 99<span>{{$t('potenciar.wizard2.card1.mes')}}</span></p>
                                <p>{{$t('potenciar.wizard2.card2.p1')}}</p>
                                <button type="button" class="btn-potenciar" @click="Step(379700)">{{$t('potenciar.wizard2.card1.btn')}}</button>
                                <p>{{$t('potenciar.wizard2.card2.mencion')}}</p>
                                <p>{{$t('potenciar.wizard2.card2.p-ultimate')}}</p>
                            </div>
                        </div>
                    </card>
                </div>
                <div class="col-12 col-lg-4">
                    <card type="pontenciar-two  default">
                        <div class="pagos text-center">
                            <p>{{$t('potenciar.wizard2.card1.item1')}}</p>
                            <p>{{$t('potenciar.wizard2.card3.item2')}}</p>
                            <p>$ 199<span>{{$t('potenciar.wizard2.card1.item2')}}</span></p>
                            <p>{{$t('potenciar.wizard2.card3.p1')}}</p>
                            <button type="button" class=" btn-potenciar" @click="Step(763200)">{{$t('potenciar.wizard2.card1.btn')}}</button>
                            <p>{{$t('potenciar.wizard2.card3.mencion')}}</p>
                            <p>{{$t('potenciar.wizard2.card3.p-ultimate')}}</p>
                        </div>
                    </card>
                </div>
            </div>
            <div class="row justify-content-center" v-show="potenciarstep == 3">
                <div class="col-12 col-lg-7">
                    <card type="potenciar default">
                        <div class="metodopago">
                            <p>{{$t('potenciar.wizard3.title')}}</p>
                            <form id="form-checkout" @submit.prevent="prueba1">
                                <div class="input-group mb-3">
                                    <ValidationProvider
                                        name="tarjeta"
                                        rules="required|integer"
                                        v-slot="{ classes, errors }"
                                    >
                                        <div class="control" :class="classes">
                                            <label for="tarjeta" class="d-none">{{$t('accounts.wizard1.label1')}}</label>
                                            <input type="text" v-model="tajeta" name="tarjeta" id="form-checkout__cardNumber" class="form-control excep-input" />
                                            <div class="input-group-append">
                                                <span class="fab fa-cc-mastercard"></span>
                                                <span class="fab fa-cc-visa"></span>
                                            </div>
                                            <span class="msg-error">{{ errors[0] }}</span>
                                        </div>
                                    </ValidationProvider>
                                    <!-- <input type="text" v-model="tajeta" name="cardNumber" id="form-checkout__cardNumber" class="form-control excep-input" /> -->
                                </div>
                                <div class="form-group">
                                    <ValidationProvider
                                        :name="$t('potenciar.wizard3.label1')"
                                        rules="required"
                                        v-slot="{ classes, errors }"
                                    >
                                        <div class="control" :class="classes">
                                            <label for="form-checkout__cardholderName">{{$t('potenciar.wizard3.label1')}}</label>
                                            <input type="text" v-model="namecomplete" name="cardholderName" id="form-checkout__cardholderName"/>
                                            <span class="msg-error">{{ errors[0] }}</span>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-lg-4">
                                        <div class="form-group">
                                            <ValidationProvider
                                                :name="$t('potenciar.wizard3.label2')"
                                                rules="required|integer|max:2"
                                                v-slot="{ classes, errors }"
                                            >
                                                <div class="control" :class="classes">
                                                    <label for="form-checkout__cardExpirationMonth">{{$t('potenciar.wizard3.label2')}}</label>
                                                    <input type="text" v-model="mounth" name="cardExpirationMonth" id="form-checkout__cardExpirationMonth" />
                                                    <span class="msg-error">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-4">
                                        <div class="form-group">
                                            <ValidationProvider
                                                :name="$t('potenciar.wizard3.label3')"
                                                rules="required|integer|max:2"
                                                v-slot="{ classes, errors }"
                                            >
                                                <div class="control" :class="classes">
                                                    <label for="form-checkout__cardExpirationYear">{{$t('potenciar.wizard3.label3')}}</label>
                                                    <input type="text" v-model="year" name="cardExpirationYear" id="form-checkout__cardExpirationYear" />
                                                    <span class="msg-error">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-4">
                                        <div class="form-group">
                                            <ValidationProvider
                                                name="CVV"
                                                rules="required|integer|max:3"
                                                v-slot="{ classes, errors }"
                                            >
                                                <div class="control" :class="classes">
                                                    <label for="form-checkout__securityCode">CVV</label>
                                                    <input type="text" v-model="CVV" name="securityCode" id="form-checkout__securityCode" />
                                                    <span class="msg-error">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <div class="form-group">
                                            <ValidationProvider
                                                :name="$t('potenciar.wizard3.label5')"
                                                rules="required"
                                                v-slot="{ classes, errors }"
                                            >
                                                <div class="control" :class="classes">
                                                    <label for="form-checkout__identificationType">{{$t('potenciar.wizard3.label5')}}</label>
                                                    <select name="identificationType" v-model="typedocument" class="form-control" id="form-checkout__identificationType"></select>
                                                    <span class="msg-error">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <div class="form-group">
                                            <ValidationProvider
                                                :name="$t('potenciar.wizard3.label6')"
                                                rules="required|integer"
                                                v-slot="{ classes, errors }"
                                            >
                                                <div class="control" :class="classes">
                                                    <label for="form-checkout__identificationNumber">{{$t('potenciar.wizard3.label6')}}</label>
                                                    <input type="text" v-model="document" name="identificationNumber" id="form-checkout__identificationNumber"/>
                                                    <span class="msg-error">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <ValidationProvider
                                        :name="$t('potenciar.wizard3.label7')"
                                        rules="required|email"
                                        v-slot="{ classes, errors }"
                                    >
                                        <div class="control" :class="classes">
                                            <label for="form-checkout__cardholderEmail">{{$t('potenciar.wizard3.label7')}}</label>
                                            <input type="email" v-model="email" name="cardholderEmail" id="form-checkout__cardholderEmail"/>
                                            <span class="msg-error">{{ errors[0] }}</span>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <div class="form-group">
                                            <ValidationProvider
                                                :name="$t('potenciar.wizard3.label8')"
                                                rules="required"
                                                v-slot="{ classes, errors }"
                                            >
                                                <div class="control" :class="classes">
                                                    <label for="form-checkout__issuer">{{$t('potenciar.wizard3.label8')}}</label>
                                                    <select name="issuer" v-model="bank" class="form-control" id="form-checkout__issuer"></select>
                                                    <span class="msg-error">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <div class="form-group">
                                            <ValidationProvider
                                                :name="$t('potenciar.wizard3.label9')"
                                                rules="required"
                                                v-slot="{ classes, errors }"
                                            >
                                                <div class="control" :class="classes">
                                                    <label for="form-checkout__installments">{{$t('potenciar.wizard3.label9')}}</label>
                                                    <select name="installments" v-model="cuotas" class="form-control" id="form-checkout__installments"></select>
                                                    <span class="msg-error">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-right">
                                    <button type="submit" class="btn-wizard" id="form-checkout__submit">{{$t('potenciar.wizard3.btn')}}</button>
                                </div>
                            </form>   
                        </div>
                        
                    </card>
                </div>
            </div>
        </div>
        <modalpeq group="foo1" position="bottom left" />
    </div>
</template>

<!--<script src="https://sdk.mercadopago.com/js/v2"></script>-->
<script>
import jQuery from "jquery";
window.$ = window.jQuery = jQuery;
import Card from "@/components/card/Card.component";
import {mapActions, mapState, mapMutations} from "vuex";
import {Trans} from '../plugins/Translation'
// import TextInput from "@/components/inputForms/InputForms.component";
import { validate } from "vee-validate";
export default {
    name: "Potenciar",
    components: {
        Card,
    },
    data() {
        return {
            plan: null,
            typeplan: 'mensual',
            tajeta: null,
            namecomplete: '',
            mounth: '',
            year: '',
            CVV: '',
            document: '',
            email: '',
            typedocument: null,
            cuotas: null,
            bank: null,
            id_empresa: ''
        };
    },
    beforeRouteLeave(to, from, next) {
        let idioma = Trans.getUserLocale().locale.includes('en') ? idioma = "en" : idioma = "es"
        to.fullPath == `/${idioma}/enhance` ? this.changePotenciar(true) : this.changePotenciar(false);
        next();
    },
    computed:{
      ...mapState('potenciar',  ["potenciarstep"]),
      ...mapState(["social"]),
    },
    async created(){
        let idioma = Trans.getUserLocale().locale.includes('en') ? idioma = "en" : idioma = "es"
        this.$router.history.current.fullPath == `/${idioma}/enhance` ? this.changePotenciar(true) : this.changePotenciar(false);        
        this.changeStep(1);
    },
    methods: {
        ...mapActions(['changePotenciar']),
        ...mapMutations(["Carga"]),
        ...mapActions('potenciar', ['changeStep']),
        
        Step(elem){
            elem != undefined ? this.plan = elem : console.log('sin costo');
            this.changeStep();
        },
        load_form(){
          this.mp = new MercadoPago('TEST-8753c9ab-fd12-4338-9ea2-3d1705c29c65');
          const cardForm = this.mp.cardForm({
            amount: this.plan.toString(),
            autoMount: true,
            processingMode: 'aggregator',
            form: {
              id: "form-checkout",
              cardholderName: {
                id: "form-checkout__cardholderName",
                placeholder: "Titular de la tarjeta",
              },
              cardholderEmail: {
                id: "form-checkout__cardholderEmail",
                placeholder: "E-mail",
              },
              cardNumber: {
                id: "form-checkout__cardNumber",
                placeholder: "Número de la tarjeta",
              },
              cardExpirationMonth: {
                id: "form-checkout__cardExpirationMonth",
                placeholder: "Mes de vencimiento",
              },
              cardExpirationYear: {
                id: "form-checkout__cardExpirationYear",
                placeholder: "Año de vencimiento",
              },
              securityCode: {
                id: "form-checkout__securityCode",
                placeholder: "Código de seguridad",
              },
              installments: {
                id: "form-checkout__installments",
                placeholder: "Cuotas",
              },
              identificationType: {
                id: "form-checkout__identificationType",
                placeholder: "Tipo de documento",
              },
              identificationNumber: {
                id: "form-checkout__identificationNumber",
                placeholder: "Número de documento",
              },
              issuer: {
                id: "form-checkout__issuer",
                placeholder: "Banco emisor",
              },
            },
            callbacks: {
              onFormMounted: error => {
                if (error) return console.warn("Form Mounted handling error: ", error);
                console.log("Form mounted");
              },
              onSubmit: event => {
                event.preventDefault();
                // Get the payment data from the Form
                const {
                  paymentMethodId,
                  issuerId,
                  cardholderEmail,
                  amount,
                  token,
                  installments,
                  identificationNumber,
                  identificationType,
                  processingMode,
                } = cardForm.getCardFormData();
                // add other info
                const name = document.getElementById('form-checkout__cardholderName').value;
                // Get account data
                const data =  this.social.filter(item => item.view == true);
                const id_empresa =  data.shift().Id_Empresa;
                this.Carga();
                // Request the payment
                this.axios.post('bextsocial/process_payment', {
                  transaction_amount: amount,
                  token: token,
                  description: "BextSocial Suscription Payment",
                  installments: installments,
                  payment_method_id: paymentMethodId,
                  issuer_id: issuerId,
                  email: cardholderEmail,
                  doc_type: identificationType,
                  doc_number: identificationNumber,
                  issuer_name: name,
                  plan_type: this.typeplan,
                  id_empresa: id_empresa,
                  processing_mode: processingMode
                })
                .then(res => {
                  // TODO: Set the answer with the correct reponse
                  this.Carga();
                  next();
                })
                .catch(e => {
                  // TODO: If has an error catch it and log it
                  console.log("Error on call");
                  console.log(e);
                });
              },
              onFetching: (resource) => {
                console.log("Fetching resource: ", resource);
              },
            },
          });
        },
        async prueba1(){
            const target = await validate(this.tajeta, "required", {
                name: "tarjeta",
            });
            const name = await validate(this.namecomplete, "required", {
                name: "Prueba",
            });
            const mounth = await validate(this.mounth, "required", {
                name: "Prueba",
            });
            const year = await validate(this.year, "required", {
                name: "Prueba",
            });
            const cvv = await validate(this.CVV, "required", {
                name: "Prueba",
            });
            const document = await validate(this.document, "required", {
                name: "Prueba",
            });
            const email = await validate(this.email, "required", {
                name: "Prueba",
            });
            const typedocument = await validate(this.typedocument, "required", {
                name: "Prueba",
            });
            const cuotas = await validate(this.cuotas, "required", {
                name: "Prueba",
            });
            const bank = await validate(this.bank, "required", {
                name: "Prueba",
            });
            if (
                target.valid == false ||
                name.valid == false || mounth == false || year == false ||
                cvv == false || document == false || email == false || typedocument == false
                || cuotas == false || bank == false
            ) {
                this.$hijonew({
                group: "foo1",
                type: "danger",
                title: "Error",
                text: "Todos los campos son obligatorios",
                duration: 1000,
                speed: 1000,
                });
            }
        }
    },
    mounted(){
      let mercadoLibreScript = document.createElement('script');
      mercadoLibreScript.setAttribute('src', 'https://sdk.mercadopago.com/js/v2');
      document.head.appendChild(mercadoLibreScript);
      const data =  this.social.filter(item => item.view == true);
      console.log(data);
    },
    watch: {
        potenciarstep(newVal, oldVal) {
            if(newVal == 3){
                this.Carga();
                setTimeout(() => {
                  this.Carga();
                  this.load_form();
                }, 1000);
                
            }
        }
    }
}
</script>