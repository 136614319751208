<template>
  <div class="container createsearch">
    <card type="search">
      <template slot="header">
        <h2>{{$t('create-search.title')}}</h2>
      </template>
      <p>{{$t('create-search.desc')}}</p>
      <div class="row mt-5">
        <div class="col-12 col-md-9">
          <div class="form-group cre-se">
            <div class="icon-search">
              <font-awesome-icon icon="search" size="1x" />
            </div>
            <vue-tags-input
              v-model="tag"
              :tags="tags"
              :placeholder="$t('navbar.item2')"
              @tags-changed="newTags => tags = newTags"
            />
          </div>
          <RangeDateSocial />
        </div>
        <div class="col-12 col-md-3">
          <button class="btn btn-bextsocial" @click="saveRest" type="button">{{$t('create-search.button')}}</button>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import Card from "@/components/card/Card.component.vue";
import VueTagsInput from "@johmun/vue-tags-input";
import { createHelpers } from 'vuex-map-fields';
import RangeDateSocial from "@/components/rangedatesocial/RangeDateSocial.vue";
const { mapFields } = createHelpers({
  getterType: 'menciones/getField',
  mutationType: 'menciones/updateField',
});
export default {
  name: "CreateSearch",
  components: {
    Card,
    VueTagsInput,
    RangeDateSocial
  },
  data() {
    return {
      placeholder: "Ingrese una palabra",
      // tag: "",
      // tags: [],
      // rest: ""
    };
  },
  computed: {
    ...mapFields(['tags', 'tag']),
    
    // The `mapFields` function takes an array of
    // field names and generates corresponding
    // computed properties with getter and setter
    // functions for accessing the Vuex store.
   // ...mapFields('redes', ['tags','tag']),
  },
  methods: {
     //...mapMutations("redes",["getRedes"])
     saveRest(){
      localStorage.setItem("rest", JSON.stringify(this.tags));
      console.log(localStorage.rest);
      this.$router.push({ name: 'Dashboard' })
     }
  }
};
</script>
<style lang="scss" scope>
.createsearch {
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 150px;
  padding-right: 150px;
  .card-search {
    padding: 80px 99px;
    button.btn.btn-bextsocial{
      width: auto;
      padding: 6px 32px;
      font-size:18px;
    }
  }
  .cre-se {
    position: relative;
    .icon-search {
      padding: 7px 10px;
      position: absolute;
      z-index: 1;
      left: 0;
      svg,
      i {
        color: #bcbccb;
      }
    }
    .vue-tags-input {
      .ti-input {
        border-radius: 5px;
      }
      .ti-tags {
        padding-left: 30px;
      }
      .ti-tag[data-v-61d92e31] {
        background-color: transparent;
        border-radius: 5px;
        border: 1px solid #a1a0a0;
        color: #707070;
      }
    }
  }
}
h2 {
  font-size: 20px;
  color: #707070;
}
.nabvar-input-2 {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #707070;
  .input-group-append {
    .input-group-text {
      background: transparent;
      border: none;
      svg,
      i {
        color: #bcbccb;
      }
    }
  }
  input {
    border: none;
    &:focus {
      border: none;
      box-shadow: none;
    }
  }
}
</style>