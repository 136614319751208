<template>
  <div class="container comentarios mt-4">
    <div class="row">
      <RangeDateSocial />
      <div class="col-12">
        <social-filter></social-filter>
      </div>
      <div class="col-12 col-md-5 mt-4 mln-5" v-if="loaded">
        <card :type="clase+' '+$route.params.tipo">
          <template slot="header">
            <div class="row posi">
              <div class="col-6">
                <img src="../assets/image/instagram.png" alt="Instagram" width="40" height="40"/>
                <img src="../assets/image/facebook.png" alt="Facebook" width="40" height="40"/>
                <img src="../assets/image/twiter.png" alt="Twitter" width="35" height="35"/>
              </div>
              <div class="col-6 text-right">
                <p>Menciones {{ $route.params.tipo }}</p>
              </div>
            </div>
          </template>
          <div class="content-positivas">
          <!--@click="show = !show"-->
            <ul class="no-padd list">
              <li :class="item.Red_social" @click="getUserMencion($event), getMencionUserComputed"  v-for="(item,index) in mencion" :key="index">
                  <div class="row">
                    <div class="col-x">
                      <img v-if="item.Imagen_Perfil" :src="item.Imagen_Perfil" :alt="item.Nombre_Usuario" style="width:48px;height:48px;"/>
                      <img v-else src="../assets/logo.png" alt="prueba vue"/>
                    </div>
                    <div class="col-9">
                      <img v-if="item.Red_Social == 'Facebook'" src="../assets/image/facebook.png" alt="Facebook" style="width:30px;height:30px;" />
                      <img v-else-if="item.Red_Social == 'Instagram'" src="../assets/image/instagram.png" alt="Facebook" style="width:30px;height:30px;"/>
                      <img v-else src="../assets/image/twiter.png" alt="Twitter" style="width:30px;height:30px;"/>
                      <span><strong>{{ item.Usuario }}</strong></span>
                      <p class="fecha"> {{ item.Fecha }}</p>
                      <p>{{ item.Comentario }}</p>
                    </div>
                  </div>
                </li>
            </ul>
          </div>
        </card>
      </div>
      <transition name="fade">
        <div class="col-12 col-md-7-x" v-if="!loadeduser">
          <card type="seleccion default">
            <template slot="header">
              <h3>Selecciona el mensaje
              </h3>
            </template>
            <p>Conectar una cuenta a Bextsocial te ayudará a encontrar más resultados en las redes sociales más rápido. Al conectar una cuenta, también podrás dar me gusta, responder y compartir publicaciones directamente desde la aplicación.</p>
          </card>
        </div>
      </transition>
      <transition name="slide-fade">
        <div class="col-12 col-md-7" v-if="loadeduser">
          <card type="post default">
              <template slot="header">
                <h4>Historial de menciones</h4>
                <p>filtro </p>
              </template>
              <ul class="no-padd list" v-if="mencionuser != null">
                  <li :class="element.Red_social" v-for="(element,index) in getMencionUserComputed" :key="index">
                    <div class="row">
                      <div class="col-x">
                        <img v-if="element.Imagen_Perfil" :src="element.Imagen_Perfil" :alt="element.Nombre_Usuario" style="width:48px;height:48px;"/>
                        <img v-else src="../assets/logo.png" alt="prueba vue" style="width:48px;height:48px;"/>
                      </div>
                      <div class="col-9">
                        <img v-if="element.Red_Social == 'Facebook'" src="../assets/image/facebook.png" alt="Facebook" style="width:30px;height:30px;" />
                        <img v-else-if="element.Red_Social == 'Instagram'" src="../assets/image/instagram.png" alt="Facebook" style="width:30px;height:30px;"/>
                        <img v-else src="../assets/image/twiter.png" alt="Twitter" style="width:30px;height:30px;"/>
                        <span><strong>{{ element.Usuario }}</strong></span>
                        <p class="fecha"> {{ element.Fecha }}</p>
                        <p>{{ element.Comentario }}</p>
                      </div>
                    </div>
                  </li>
              </ul>
          </card>
        </div>
      </transition>
    </div>
    <modalpeq group="foo1" position="bottom left"  />
  </div>
</template>

<script>
import moment from "moment";
import SocialFilter from "@/components/redesSociales/social.component"
import RangeDateSocial from "@/components/rangedatesocial/RangeDateSocial.vue";
import Card from "@/components/card/Card.component.vue";
import {mapState, mapMutations, mapActions, Store} from "vuex";

export default {
  name: "Menciones",
  components: {
    Card,
    RangeDateSocial,
    SocialFilter
  },
  data(){
    return{
      show: true,
      // redes: [],
      mencion: null,
      clase: "menciones",
      loaded: false,
      loadeduser: false,
      mencionuser: null,
    }
  },
  computed:{
    ...mapState("vision", ["dateRange", "fechas"]),
    ...mapState("menciones", ["tags", "tag"]),
    ...mapState("user", ["users"]),
    ...mapState(["socialFacebook", "socialInstagram", "socialTwitter", "social"]),
    getMencionUserComputed(){
      debugger
        let mencions = this.mencion
        console.log(this.mencion);
        const usermencions = mencions.filter(element =>  element.Usuario == this.$store.state.usermencion)
        this.mencionuser = usermencions;
        this.loadeduser = true;
        return usermencions;
    }
  },
  created(){
    //this.listarNotas();
    // this.Carga();
    this.dateNow();
    this.getMenciones();
  },
  methods:{
    ...mapMutations(["getUserMencion"]),
    ...mapMutations("vision", ["dateNow"]),
    ...mapMutations(["Carga"]),
    getTag(){
      let prueba = "";
        // let objetotags = JSON.parse(localStorage.getItem("rest"));
        let objetotags = this.tags;
        if(objetotags != null){
          objetotags.forEach(function(x, i) {
            if (i % 2 === 0) {
              if (i == 0) {
                if (i == objetotags.length - 1) {
                  prueba = x.text;
                } else {
                  prueba = x.text + ",";
                }
              } else if (i == objetotags.length - 1) {
                prueba = prueba + "," + x.text;
              } else {
                prueba = prueba + "," + x.text + ",";
              }
            } else {
              prueba = prueba + x.text;
            }
          });
          return prueba;
        }
        else{
          alert("error: no ha registro ninguna busqueda")
          this.Carga();
        }
        
        
    },
    async getMenciones(){
      try {
        const tag = this.getTag();
        if(tag != ""){
          this.Carga();
          let params = this.$route.params.tipo
        let datos = null;
        const id = this.social.filter(item => item.view == true)
        // if(!this.fechas[0].result){
        //   const { data } = await this.axios.get(`/bextsocial/mencion/${id[0].Id_Empresa}/${params}/${prueba}`);
        //   datos =  data;
        // }
        // else{
          let redSq = [];
          if(this.socialFacebook){
            redSq.push("Facebook");
          }
          if(this.socialInstagram){
            redSq.push("Instagram");
          }
          if(this.socialTwitter){
            redSq.push("Twitter");
          }
          let fecha = this.fechas[0].start;
          let fecha1 = this.fechas[0].end;
          debugger
          const { data } = await this.axios.get(`/bextsocial/menciondate/${id[0].Id_Empresa}/${params}/${fecha},${fecha1}/${tag}/${redSq}`);
          datos =  data;
          // debugger
        // }
        // Validación para saber si es una actualización o esta cargando la página
        if(datos == "No se encontro data relacionada a esas fechas y tags"){
          this.loaded = false;
          this.loadeduser = false;
            this.Carga();
            this.$hijonew({
                group: 'foo1',
                type: 'danger',
                title: "Error",
                text: 'No se encontro data relacionada con la fechas asignandas o con la palabra o frase que busca',
                duration: 1000,
                speed: 1000
            })
        } else{
          let mencions = datos;
          mencions.forEach(element => {
            const date = moment(element.Fecha, "MMMM DD YYYY").format("MM/DD HH:mm");
            element.Fecha = date;
          })
          mencions.reverse()
          this.Carga()
          this.loadeduser = false;
          this.mencion = mencions
          this.loaded = true;
          
        }
        }
        else{
          this.$hijonew({
              group: 'foo1',
              type: 'danger',
              title: "Error",
              text: 'Debes incluir tag sobre el buscador',
              duration: 1000,
              speed: 1000
          })
        }
        

      } catch (error) {
        this.Carga()
        this.$hijonew({
            group: 'foo1',
            type: 'danger',
            title: "Error",
            text: 'Error de conexión',
            duration: 1000,
            speed: 1000
        })
        console.log(error)
      }
    },
  },
  watch: {
    // observador para actualización
    dateRange: function (newVal, oldVal) {
          this.dateNow();
          this.getMenciones()
    },
    tags: function(newVal, oldVal){
        this.getMenciones()
    },
    socialFacebook(){
      this.getMenciones()
    },
    socialInstagram(){
      this.getMenciones()
    },
    socialTwitter(){
      this.getMenciones()
    }
  }
};
</script>
<style scoped lang="scss">
  .list-item {
  display: inline-block;
  margin-right: 10px;
  }
  .list-enter-active, .list-leave-active {
    transition: all 1s;
  }
  .list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(30px);
  }
.comentarios{
    padding-left: 50px;
    padding-right: 50px;
    .mln-5{
      margin-left: -50px; 
    }
    .card-header{
      padding: 4px 30px 4px 10px;
    }
    .posi{
      .col-6{
        p{
          color: white;
          margin: 0;
          position: absolute;
          right: 15px;
          top: 20%;
          bottom: 20%;
        }
      }
    }
    .card-menciones{
      .card-body{
      li{
        padding: 10px 10px 10px 10px;
        cursor: pointer;
        &:hover{
          background: #DEDCFF;
        }
      }
    }
    }
    .col-x{
        flex: 0 0 20%;
        max-width: 20%;
        padding-left: 15px;
      }
      .col-9{
        padding: 0;
        img{
          margin-right: 10px;
        }
        span{
          font-size: 15px;
        }
        p:last-child{
          margin-left: -20px;
        }
      }
    .col-md-7-x{
      flex: 0 0 62.1%;
      max-width: 62.1%;
    }
    .card-post{
      margin-top: 10%; 
      -webkit-transition: all 0.8s ease;
      -moz-transition: all 0.8s ease;
      -ms-transition: all 0.8s ease;
      -o-transition: all 0.8s ease;
      transition: all 0.8s ease;
    }
    .card-seleccion{
      margin-top: 30%; 
      padding: 30px 70px;
      -webkit-transition: all 0.8s ease;
      -moz-transition: all 0.8s ease;
      -ms-transition: all 0.8s ease;
      -o-transition: all 0.8s ease;
      transition: all 0.8s ease;
      .card-body{
        h3{
          text-align: center;
        }
        p{
          text-align: center;
        }
      }
    }
}
</style>